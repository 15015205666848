import React, { useRef, useState } from "react";
import {
  Slide,
  Paper,
  Typography,
  List,
  IconButton,
  ListItemSecondaryAction,
  ListItem,
  ListItemAvatar,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Badge,
  Tooltip,
} from "@material-ui/core";
import { ReactComponent as ScreenShareOnIcon } from "../assets/ScreenShareOn-bottom.svg";
import { ReactComponent as VideoCamOffIcon } from "../assets/VideoCamOff-userList.svg";
import { ReactComponent as VideoCamOnIcon } from "../assets/VideoCamOn-userList.svg";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import MoreVertTwoToneIcon from "@material-ui/icons/MoreVertTwoTone";
import UserAvatar from "../Utils/UserAvatar";
import AudioLevelIndicator from "../Utils/AudioLevels";
import { ReactComponent as MicOffIcon } from "../assets/Mic-off-user.svg";
import { ReactComponent as MicOffIconOutLined } from "../assets/Micoff-bottom.svg";

import userListStyle from "./UserListStyles";
import PropTypes from "prop-types";
import { ReactComponent as PinIcon } from "../assets/pin.svg";
import { ReactComponent as UnPinIcon } from "../assets/unpin.svg";

import { toPascalCase } from "../../../lib/helper";
import { ReactComponent as FullSignalIcon } from "../assets/signal full.svg";
import { ReactComponent as StrongSignalIcon } from "../assets/signal strong.svg";
import { ReactComponent as MediumSignalIcon } from "../assets/signal medium.svg";
import { ReactComponent as LowSignalIcon } from "../assets/signal low.svg";
import { ReactComponent as VeryLowSignalIcon } from "../assets/signal very low.svg";
import { ReactComponent as SignalLoading } from "../assets/signalLoading.svg";

import { LocalizedString } from "../../../Utils/Constants";
let counter = 1;
import { isScreenSharingTrack } from "../../../lib/utils";
let networkColor = (connectionQuality) =>
  connectionQuality ? (
    connectionQuality < 20 ? (
      <VeryLowSignalIcon style={{ height: 24, width: 24 }} />
    ) : connectionQuality < 40 ? (
      <LowSignalIcon style={{ height: 24, width: 24 }} />
    ) : connectionQuality < 60 ? (
      <MediumSignalIcon style={{ height: 24, width: 24 }} />
    ) : connectionQuality < 80 ? (
      <StrongSignalIcon style={{ height: 24, width: 24 }} />
    ) : (
      <FullSignalIcon style={{ height: 24, width: 24 }} />
    )
  ) : (
    <SignalLoading style={{ height: 24, width: 24 }} />
  );
const UserList = (props) => {
  const [showOptionFor, setShowOptionFor] = useState(null);
  const classes = userListStyle();
  const SelfRef = useRef([]);

  /**
   * UI for userItem in user list
   * @returns {Component}
   */
  const renderList = () => {
    return props.users.map((data, index) => {
      console.log("rernder called for", data.id);
      return (
        <div key={data.id} data-testid={`cc-userlist`}>
          <ListItem>
            <ListItemAvatar style={{ minWidth: 40 }}>
              <div style={{ position: "relative" }}>
                <Tooltip
                  disableHoverListener={data.id !== props.recorderId}
                  title={"Recorder"}
                  arrow
                >
                  <Badge
                    invisible={data.id !== props.recorderId}
                    color='error'
                    badgeContent='R'
                    variant='standard'
                  >
                    <UserAvatar
                      size='small'
                      style={{ height: 32, width: 32 }}
                      user={
                        data?.screenShareUserID
                          ? props.users.find((item) => item.id == data.screenShareUserID)
                          : data
                      }
                    />
                  </Badge>
                </Tooltip>
                {isScreenSharingTrack(data?.videoTrack) && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-5px",
                      backgroundColor: "#242b2f",
                      right: "0px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      borderRadius: "4px",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <ScreenShareOnIcon style={{ height: 14 }} />
                  </div>
                )}
              </div>
            </ListItemAvatar>
            <Typography variant='h5' className={classes.userNameStyle}>
              {toPascalCase(data.name || "")}
            </Typography>
            <ListItemSecondaryAction className={classes.iconsContainer}>
              <div
                style={{
                  display: "flex",
                  width: 32,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {networkColor ? networkColor(props.connectionQuality[data.id]) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  width: 32,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {data.isAudioMuted || !data.audioTrack ? (
                  <MicOffIcon style={{ color: "rgb(6, 170, 33)" }} fontSize='medium' />
                ) : (
                  <AudioLevelIndicator
                    noisy={props.noisyMics[data.id] == true}
                    track={data.audioTrack}
                  />
                )}
              </div>
              {!props.isAudioOnly && (
                <div
                  style={{
                    display: "flex",
                    width: 32,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {data.isVideoMuted || !data.videoTrack ? (
                    <VideoCamOffIcon style={{ color: "rgb(6, 170, 33)" }} fontSize={"default"} />
                  ) : (
                    <VideoCamOnIcon className={classes.videoOnIconStyle} fontSize={"default"} />
                  )}
                </div>
              )}
              <IconButton
                data-testid={`cc-userlist-menu-button-${data.id}`}
                component='span'
                className={classes.iconButtonstyle}
                ref={(el) => (SelfRef.current[index] = el)}
                onClick={(e) => {
                  e.preventDefault();
                  setShowOptionFor(data.id);
                }}
                edge='end'
                aria-controls='simple-menu'
              >
                <MoreVertTwoToneIcon className={classes.colorWhite} />
              </IconButton>

              <Popper
                open={showOptionFor === data.id}
                role={undefined}
                anchorEl={SelfRef.current[index]}
                transition
                disablePortal
                placement={"left"}
                modifiers={{
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: "scrollParent",
                  },
                }}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: "left-end",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={() => setShowOptionFor(null)}>
                        <MenuList
                          id='menu-list-grow'
                          onKeyDown={() => {
                            setShowOptionFor(null);
                          }}
                          style={{ backgroundColor: "rgb(30,30,30)" }}
                        >
                          {index !== 0 && !data.isAudioMuted && data.audioTrack ? (
                            <MenuItem
                              data-testid={`cc-mute-user`}
                              onClick={() => {
                                setShowOptionFor(null);
                                props.muteUser(data);
                              }}
                            >
                              <div className={classes.menuIconContainer}>
                                <MicOffIconOutLined
                                  className={classes.colorWhite}
                                  fontSize='medium'
                                />
                              </div>
                              <Typography className={classes.colorWhite} variant='h5'>
                                {props.localizedObject["MUTE_USER"]}
                              </Typography>
                            </MenuItem>
                          ) : null}
                          {/* <MenuItem
                        onClick={() => {
                          setShowOptionFor(null);
                          props.kickUser(data.id);
                        }}
                      >
                        <div className={classes.menuIconContainer}>
                          <RemoveCircleOutlineOutlined fontSize="medium" />
                        </div>
                        <Typography variant="inherit">Kick</Typography>
                      </MenuItem> */}
                          {props.pinnedUserId === data.id ? (
                            <MenuItem
                              onClick={() => {
                                setShowOptionFor(null);
                                props.unPinUser(data.id);
                              }}
                            >
                              <div className={classes.menuIconContainer}>
                                <UnPinIcon
                                  style={{ height: 24, width: 24 }}
                                  className={classes.colorWhite}
                                />
                              </div>
                              <Typography className={classes.colorWhite} variant='h5'>
                                {props.localizedObject["UNPIN_USER"]}
                              </Typography>
                            </MenuItem>
                          ) : (
                            <MenuItem
                              onClick={() => {
                                setShowOptionFor(null);
                                props.pinUser(data);
                              }}
                            >
                              <div className={classes.menuIconContainer}>
                                <PinIcon
                                  style={{ height: 24, width: 24 }}
                                  className={classes.colorWhite}
                                />
                              </div>
                              <Typography className={classes.colorWhite} variant='h5'>
                                {props.localizedObject["PIN_USER"]}
                              </Typography>
                            </MenuItem>
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </ListItemSecondaryAction>
          </ListItem>
        </div>
      );
    });
  };
  console.log("userlist renbder count=", counter);
  counter++;
  return (
    <div
      className={classes.container}
      // maxWidth="xs"
      fixed
    >
      <Slide className={classes.sliderContainer} direction='left' in={true}>
        <Paper className={classes.paperContainer}>
          <div className={classes.title}>
            <Typography className={classes.colorWhite} variant='h4'>
              {props.localizedObject["USER_LIST"]}
            </Typography>
            <IconButton
              component='span'
              style={{ backgroundColor: "#31383e", padding: 8 }}
              onClick={(e) => {
                e.stopPropagation();
                props.close();
              }}
              size={"small"}
            >
              <CloseIcon
                style={{ height: 16, width: 16 }}
                className={classes.colorWhite}
                fontSize='small'
              />
            </IconButton>
          </div>

          <List className={"scroll"}>{renderList()}</List>
        </Paper>
      </Slide>
    </div>
  );
};

export default React.memo(UserList);

UserList.propTypes = {
  users: PropTypes.array,
  close: PropTypes.func,
  pinUser: PropTypes.func,
  unPinUser: PropTypes.func,
  pinnedUserId: PropTypes.string,
  muteUser: PropTypes.func,
  kickUser: PropTypes.func,
  localizedObject: PropTypes.object,
  recorderId: PropTypes.string,
  connectionQuality: PropTypes.object,
};

UserList.defaultProps = {
  users: [],
  close: () => {},
  pinUser: () => {},
  unPinUser: () => {},
  pinnedUserId: "",
  muteUser: () => {},
  kickUser: () => {},
  localizedObject: LocalizedString,
  recorderId: "",
  connectionQuality: {},
};
