import makeStyles from "@material-ui/core/styles/makeStyles";
export const bottomButtonStyle = makeStyles(() => {
  return {
    otherOptionsContainer: {
      position: "absolute",
      display: "flex",
      right: "8px",
      bottom: "0.1vh",
    },
    bottomMainContainer: {
      height: "12vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "80px",
      backgroundImage: "linear-gradient(#1c2226,#13171A)",
    },
    peopleIconButton: {
      border: "none",
      outline: "none",
    },
    peopleIcon: { color: "#fff" },
  };
});

export const audioButtonStyles = makeStyles((theme) => ({
  iconColor: { color: "#fff" },
  iconButton: {
    border: "none",
    outline: "none",
    marginRight: 5,
  },
  buttonContainer: {
    minHeight: "50px",
    minWidth: "50px",
    height: "7vh",
    width: "7vh",
    backgroundColor: theme.palette.buttonBackgroundColor.main,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const screenShareButtonStyles = makeStyles((theme) => ({
  iconColor: { color: "#fff" },
  iconButton: {
    border: "none",
    outline: "none",
    marginRight: 5,
  },
  buttonContainer: {
    minHeight: "50px",
    minWidth: "50px",
    height: "7vh",
    width: "7vh",
    backgroundColor: theme.palette.buttonBackgroundColor.main,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const recordingButtonStyles = makeStyles((theme) => ({
  iconColor: { color: "#fff" },
  iconButton: {
    border: "none",
    outline: "none",
    marginRight: 5,
  },
  buttonContainer: {
    minHeight: "50px",
    minWidth: "50px",
    height: "7vh",
    width: "7vh",
    backgroundColor: theme.palette.buttonBackgroundColor.main,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const videoButtonStyles = makeStyles((theme) => ({
  iconColor: { color: "#fff" },
  iconButton: {
    border: "none",
    outline: "none",
    marginRight: 5,
  },
  buttonContainer: {
    minHeight: "50px",
    minWidth: "50px",
    height: "7vh",
    width: "7vh",
    backgroundColor: theme.palette.buttonBackgroundColor.main,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const endCallButtonStyles = makeStyles((theme) => ({
  iconColor: { color: "#fff" },
  iconButton: {
    border: "none",
    outline: "none",
    marginRight: 5,
  },
  buttonContainer: {
    minHeight: "50px",
    minWidth: "50px",
    height: "7vh",
    width: "7vh",
    backgroundColor: theme.palette.error.main,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const settingStyle = makeStyles(
  (theme) => ({
    layoutDilogFormControl: { padding: 16, paddingTop: 0 },
    scrollPaper: {
      // alignItems: "baseline",
    },
    paperScrollPaper: {
      backgroundColor: "rgba(30,30,30,0.8)",
    },
    dropDownStyle: {
      minWidth: 250,
      maxWidth: 250,
      width: 250,
      color: "#fff",
    },
    dropdownRoot: {
      borderBottomColor: "#fff",
      borderBottomWidth: 2,
    },
    dropdownIcon: {
      color: "#1E79E7",
    },
    dropDownList: {
      backgroundColor: "rgba(30,30,30,1)",
    },
    audioDilogFormControl: { paddingBottom: 16, marginRight: 8 },
    videoDialogFormControl: { paddingBottom: 16 },
    iconButtonStyle: {
      border: "none",
      outline: "none",
    },
    iconColor: { color: "#fff", height: 24, width: 24 },
    menuIconContainer: { marginRight: 16 },
    closeIcon: {
      position: "absolute",
      top: 8,
      right: 8,
      backgroundColor: "#31383e",
      padding: 4,
      height: 24,
      width: 24,
    },
    whiteColor: {
      color: "#fff",
    },
    markLabel: {
      color: "#fff",
    },
    root: {
      width: "90%",
    },
    PaginatedRoot: {
      width: "50%",
    },
    markActive: {
      backgroundColor: theme.palette.primary.main,
      height: 4,
    },
  }),
  { index: 1, classNamePrefix: "CometChat" }
);

export const backgroundButtonStyle = makeStyles(
  (theme) => ({
    layoutDilogFormControl: { padding: 16, paddingTop: 0 },
    scrollPaper: {
      // alignItems: "baseline",
    },
    paperScrollPaper: {
      backgroundColor: "rgba(30,30,30,0.8)",
    },
    iconButtonStyle: {
      border: "none",
      outline: "none",
      top: -3,
    },
    iconColor: { color: "#fff", height: 24, width: 24 },

    closeIcon: {
      position: "absolute",
      top: 8,
      right: 8,
      // backgroundColor: "#31383e",
      padding: 4,
      height: 24,
      width: 24,
    },
    whiteColor: {
      color: "#fff",
    },
    noneContainer: {
      marginLeft: 5,
      marginRight: 5,
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      backgroundColor: "#fff",
      height: 60,
      width: "100%",
      borderRadius: 5,
    },
    noneFont: {
      // color: "#fff",
      textTransform: "none",
    },
    blurContainer: {
      marginLeft: 5,
      marginRight: 5,
      cursor: "pointer",
      backgroundColor: "#fff",
      borderRadius: 5,
    },
    imgContainer: {
      marginLeft: 5,
      marginRight: 5,
      cursor: "pointer",
    },
    active: {
      borderWidth: 4,
      borderColor: "cadetblue",
      borderRadius: 5,
    },
    image: {
      height: 60,
      width: 180,
      borderRadius: 5,
      objectFit: "cover",
    },
    buttonContainer: {
      height: 60,
      // minWidth: 150,
      width: "100%",
      transitionDuration: `${theme.transitions.duration.shorter}ms`,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      outline: "none !important",

      "&:hover": {
        transform: "scale(1.1)",
        borderWidth: 1,
        borderColor: "red",
      },
    },
    upperContainer: {
      paddingRight: 16,
      paddingLeft: 16,
      paddingTop: 16,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    dilogContent: {
      paddingTop: 30,
      paddingBottom: 30,
      maxHeight: 250,
    },
  }),
  { index: 1, classNamePrefix: "CometChat" }
);
