import React from "react";
// import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";
import { ReactComponent as StopScreenShareIcon } from "../assets/ScreenShareOff-bottom.svg";
import { ReactComponent as ScreenShareOnIcon } from "../assets/ScreenShareOn-bottom.svg";
import { IconButton, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

const ScreenShareButton = (props) => {
  /**
   * getIcon function simple returns the icon based on current screenshare state
   *@returns {Icon}
   */
  const getIcon = () => {
    if (props.isOn) {
      return <StopScreenShareIcon fontSize='medium' className={"bottom-buttons-icon-color"} />;
    } else {
      return (
        <ScreenShareOnIcon
          stroke='#fff'
          fontSize='medium'
          className={"bottom-buttons-icon-color"}
        />
      );
    }
  };

  //this is a check to make sure that do we need to render the screenshare
  //it can be hidden when developer whats to hide it
  if (props.isVisible) {
    return (
      <Tooltip
        title={
          props.isOn
            ? props.localizedObject["STOP_SCREEN_SHARE_TEXT"]
            : props.localizedObject["START_SCREEN_SHARE_TEXT"]
        }
        arrow
      >
        <IconButton
          id='screenShareButton'
          data-testid='cc-screen-share'
          size={"small"}
          component='span'
          className={"bottom-buttons-icon-button"}
          onClick={debounce((e) => {
            e.stopPropagation();
            props.onPress();
          }, 500)}
        >
          <div className={"cc-bottom-buttons-icon-container cc-screen-share-icon-container"}>
            {getIcon()}
          </div>
        </IconButton>
      </Tooltip>
    );
  } else {
    return null;
  }
};

ScreenShareButton.defaultProps = {
  isOn: false,
  onPress: () => {},
  isVisible: false,
  localizedObject: {},
};

ScreenShareButton.propTypes = {
  isOn: PropTypes.bool,
  isVisible: PropTypes.bool,
  onPress: PropTypes.func,
  localizedObject: PropTypes.object,
};

export default ScreenShareButton;
