import React, { Component } from "react";

const TAG = "MainVideoComponent";
export default class MainVideoComponent extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.updateSent = false;
  }

  _detachTrack = (videoTrack) => {
    if (this.videoRef.current && videoTrack && videoTrack?.detach) {
      videoTrack?.detach(this.videoRef.current);
    }
  };

  _attachTrack = (videoTrack) => {
    if (!videoTrack || !this.videoRef.current) {
      return;
    }
    console.log("videoTrack?.attach", videoTrack?.attach);
    if (videoTrack?.attach) videoTrack?.attach(this.videoRef.current);
    JitsiMeetJS.util.browser.isWebKitBased() && this.videoRef?.current?.play();
  };

  async componentDidUpdate(prevProps) {
    const currentJitsiTrack = prevProps.streamManager;
    const nextJitsiTrack = this.props.streamManager;
    if (currentJitsiTrack !== nextJitsiTrack) {
      await this._detachTrack(currentJitsiTrack);
      this._attachTrack(nextJitsiTrack);
    }
  }

  componentWillUnmount() {
    this._detachTrack(this.props.streamManager);
  }

  componentDidMount() {
    if (!this.updateSent && this.props.onplay)
      this.videoRef.current.ontimeupdate = () => {
        if (!this.updateSent && this.props.onplay && this.props.streamManager) {
          this.props.onplay();
          this.updateSent = true;
        }
      };
    this._attachTrack(this.props.streamManager);
  }

  render() {
    //return <video className={isScreenSharingTrack(this.props.videoTrack) ? "" : "rotate-180"} autoPlay={true} ref={this.videoRef} />;

    return (
      <video
        // className={"video-element"}
        className={this.props.flipped ? "flipped-video" : ""}
        style={{ ...this.props.style }}
        autoPlay
        playsInline
        webkit-playsinline='webkit-playsinline'
        ref={this.videoRef}
      />
    );
  }
}
