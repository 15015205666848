import makeStyles from "@material-ui/core/styles/makeStyles";
const userListStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: 16,
    marginBottom: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    [theme.breakpoints.only("xs")]: {
      position: "absolute",
      right: 0,
      left: 0,
      top: 0,
      bottom: "80px",
    },

    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    ["@media (width:600px)"]: {
      position: "absolute",
      right: 0,
      left: 0,
      top: 0,
      bottom: "80px",
      width: "auto",
    },
    display: "flex",
    paddingLeft: 8,
    paddingRight: 8,
  },
  userNameStyle: {
    width: 120,
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: "#fff",
    whiteSpace: "nowrap",
    height: "20px",
  },
  iconsContainer: { display: "flex", flexDirection: "row", maxWidth: "125px" },
  detailsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconStyle: { marginLeft: 5, width: 24 },
  videoOnIconStyle: { color: "rgba(0, 215, 164, 1)", marginLeft: 5, width: 24 },
  iconButtonstyle: {
    border: "none",
    outline: "none",
  },
  menuIconContainer: {
    marginRight: 15,
    // backgroundColor: "#fff",
    zIndex: 30000,
  },
  sliderContainer: {
    display: "flex",
    flex: 1,
    marginTop: 8,

    flexDirection: "column",
    zIndex: 100,
    backgroundColor: "rgb(36, 43, 48)",
  },
  paperContainer: {
    flexDirection: "column",
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
  },
  colorWhite: {
    color: "#fff",
  },
}));
export default userListStyle;
