export default class Theme {
  static theme = {
    palette: {
      primaryIconColor: {
        main: "#3399ff",
      },
      secondaryIconColor: {
        main: "#808080",
      },
      fontHeadingColor: {
        main: "#292A2c",
      },
      fontTitleColor: {
        main: "#292A2c",
      },
      fontSubTitleColor: {
        main: "#808080",
      },
      fontTextColor: {
        main: "#808080",
      },
      textColor: {
        main: "#fff",
      },
    },
    typography: {
      h4: {
        fontFamily: "Inter-Bold",
      },
      h5: {
        fontFamily: "Inter-Semibold",
      },
      h6: {
        fontFamily: "Inter-Regular",
      },
    },
    spacing: (factor) => `${1 * factor}vh`,
    margin: (factor) => `${4 * factor}px`,
  };

  static setTheme = (obj) => {
    this.theme = obj;
  };
}
