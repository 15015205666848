import {
  Button,
  Dialog,
  DialogActions,
  Grow,
  createStyles,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction='down' timeout={1000} ref={ref} {...props} />;
});

const RequestModalEndCall = (props) => {
  const { isModalVisible, handleSessionEnd, handleLeave } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={isModalVisible}
      aria-labelledby='draggable-dialog-title'
      classes={{
        paperScrollPaper: classes.paperScrollPaper,
      }}
      fullWidth={true}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.8)" } }}
      TransitionComponent={Transition}
      container={document.getElementById("cc-container")}
    >
      <DialogContent>
        <DialogContentText className={classes.title}>
          Do you want to end presentation for everyone or just leave the session.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleSessionEnd}>
          End
        </Button>
        <Button className={classes.button} onClick={handleLeave}>
          Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RequestModalEndCall;

const useStyles = makeStyles(() =>
  createStyles({
    paperScrollPaper: {
      backgroundColor: "rgba(30,30,30,0.8)",
    },
    title: {
      paddingTop: 10,
      paddingLeft: 15,
      color: "#fff",
    },
    button: {
      color: "#1E79E7",
      textTransform: "capitalize",
    },
  })
);
