const addLocalUrl = (url) => {
  return {
    type: "ADD_LOCAL_URL",
    payload: {
      localUrl: url,
    },
  };
};

const addRemoteUrl = (url) => {
  return {
    type: "ADD_REMOTE_URL",
    payload: {
      remoteUrl: url,
    },
  };
};

const addLocalTracks = (track) => {
  return {
    type: "ADD_LOCAL_TRACK",
    payload: {
      localTracks: track,
    },
  };
};

const addRemoteTracks = (track) => {
  return {
    type: "ADD_REMOTE_TRACK",
    payload: {
      remoteTracks: track,
    },
  };
};

const addRemoteVideoTrack = (videotrack) => {
  return {
    type: "ADD_REMOTE_VIDEO_TRACK",
    payload: {
      remoteVideoTrack: videotrack,
    },
  };
};

const changeReadyState = (ready) => {
  return {
    type: "CHANGE_READY_STATE",
    payload: {
      ready: ready,
    },
  };
};

const changeRoomName = (room) => {
  return {
    type: "CHANGE_ROOM_NAME",
    payload: {
      room: room,
    },
  };
};

const connectionFailed = (status) => {
  return {
    type: "CONNECTION_FAILED",
    payload: {
      status: status,
    },
  };
};

const changeAudioState = (audio) => {
  return {
    type: "CHANGE_AUDIO_STATE",
    payload: {
      audioMute: audio,
    },
  };
};

const changeAudioMode = (mode) => {
  return {
    type: "CHANGE_AUDIO_MODE",
    payload: {
      audioMode: mode,
    },
  };
};

const changeVideoState = (video) => {
  return {
    type: "CHANGE_VIDEO_STATE",
    payload: {
      videoMute: video,
    },
  };
};

const updateDominantVideoId = (id) => {
  return {
    type: "UPDATE_DOMINANT_VIDEO_ID",
    payload: id,
  };
};

const removeRemoteTrack = (id) => {
  return {
    type: "REMOVE_REMOTE_TRACK",
    payload: id,
  };
};

const removeLocalTracks = () => {
  return {
    type: "REMOVE_LOCAL_TRACKS",
    payload: "localVideo",
  };
};

const removeRemoteTracks = () => {
  return {
    type: "REMOVE_REMOTE_TRACKS",
    payload: "remoteVideo",
  };
};

const addLocalTrackId = (id) => {
  return {
    type: "ADD_LOCAL_TRACK_ID",
    payload: id,
  };
};

var data = {
  addLocalUrl,
  addLocalTrackId,
  addRemoteUrl,
  addLocalTracks,
  addRemoteTracks,
  addRemoteVideoTrack,
  changeReadyState,
  changeRoomName,
  changeAudioState,
  changeAudioMode,
  changeVideoState,
  connectionFailed,
  updateDominantVideoId,
  removeRemoteTrack,
  removeLocalTracks,
  removeRemoteTracks,
};

export default data;
