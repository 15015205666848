import React from "react";
import { ReactComponent as FiberManualRecordIcon } from "../assets/Start-recording.svg";
import { ReactComponent as StopIcon } from "../assets/Rectangle 16.svg";
import { IconButton, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

const RecordingButton = (props) => {
  /**
   * getIcon function simple returns the icon based on current audio state
   *@returns {Icon}
   */
  const getIcon = () => {
    if (props.isOn) {
      return (
        <StopIcon fontSize='medium' style={{ color: "red" }} data-testid={"stopRecordingIcon"} />
      );
    } else {
      return (
        <FiberManualRecordIcon
          fontSize='medium'
          className={"bottom-buttons-icon-color"}
          data-testid={"startRecordingIcon"}
        />
      );
    }
  };

  //this is a check to make sure that do we need to render the screenshare
  //it can be hidden when developer whats to hide it
  if (props.isVisible) {
    return (
      <Tooltip
        title={
          props.isOn
            ? props.localizedObject["STOP_RECORDING_TEXT"]
            : props.localizedObject["START_RECORDING_TEXT"]
        }
        arrow
      >
        <IconButton
          id={"recordingButton"}
          data-testid='cc-recording'
          size={"small"}
          component='span'
          className={"bottom-buttons-icon-button"}
          onClick={debounce((e) => {
            e.stopPropagation();
            props.onPress();
          }, 500)}
        >
          <div
            className={`cc-bottom-buttons-icon-container ${
              props.isOn ? "cc-start-rec-icon-container" : "cc-stop-rec-icon-container"
            }`}
          >
            {getIcon()}
          </div>
        </IconButton>
      </Tooltip>
    );
  } else {
    return null;
  }
};

RecordingButton.defaultProps = {
  isOn: false,
  onPress: () => {},
  isVisible: false,
  localizedObject: {},
};

RecordingButton.propTypes = {
  isOn: PropTypes.bool,
  isVisible: PropTypes.bool,
  onPress: PropTypes.func,
  localizedObject: PropTypes.object,
};

export default RecordingButton;
