export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const toDataUrl = (url, callback) => {
  try {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = (err) => {
      console.log("error", err);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  } catch (err) {
    console.log("error", err);
  }
};

export const setImagesInIndexBD = (db, file, fileType) => {
  let id = new Date().valueOf();
  db.collection("backgroundImages").doc(`backgroundImages_${id}`).set({
    file,
    fileType,
    id,
  });
  return id;
};

export const deleteImagesInIndexBD = (db, i) => {
  db.collection("backgroundImages").doc(`backgroundImages_${i}`).delete();
};
