import React, { Component } from "react";
import interpolate from "../../../Utils/interpolate";
import { ReactComponent as NoisyMicIcon } from "../assets/Noisy-Mic.svg";

class AudioLevelIndicator extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.heightRef = React.createRef();
  }

  setAudiolevel = (audioLevel) => {
    if (!this._isMounted) return;
    if (this.props.track && !this.props.track.isMuted()) {
      const perc = interpolate(audioLevel);
      this.heightRef.current.style.height = `${perc}%`;
    }
  };
  componentDidMount() {
    this._isMounted = true;
    if (this.props.track) {
      this.props.track.on(
        window.JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        this.setAudiolevel
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.track !== prevProps.track && prevProps.track && this.props.track) {
      prevProps.track.off(
        window.JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        this.setAudiolevel
      );

      this.props.track.on(
        window.JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        this.setAudiolevel
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.props.noisy) {
      <NoisyMicIcon />;
    }

    return (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.8333 17V21.1667V17ZM11.8333 2C10.9493 2 10.1014 2.35119 9.47631 2.97631C8.85119 3.60143 8.5 4.44928 8.5 5.33333V11.1667C8.5 12.0507 8.85119 12.8986 9.47631 13.5237C10.1014 14.1488 10.9493 14.5 11.8333 14.5C12.7174 14.5 13.5652 14.1488 14.1904 13.5237C14.8155 12.8986 15.1667 12.0507 15.1667 11.1667V5.33333C15.1667 4.44928 14.8155 3.60143 14.1904 2.97631C13.5652 2.35119 12.7174 2 11.8333 2Z'
          stroke='#00D7A4'
          stroke-width='1.25'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M17.6667 8.66675V11.1667C17.6667 12.7138 17.0521 14.1976 15.9581 15.2915C14.8642 16.3855 13.3804 17.0001 11.8333 17.0001C10.2862 17.0001 8.80251 16.3855 7.70854 15.2915C6.61458 14.1976 6 12.7138 6 11.1667V8.66675'
          stroke='#00D7A4'
          stroke-width='1.25'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <rect
          ref={this.heightRef}
          x='9.37'
          y='10.12'
          width='5.59'
          height='11.28'
          rx='0'
          fill='#00D7A4'
          style={{
            transformOrigin: "center",
            transform: "rotate(180deg)",
            height: "0%",
            transition: "height 0.2s",
          }}
        />
      </svg>
    );
  }
}

export default AudioLevelIndicator;
