import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as PinIcon } from "../assets/pin.svg";
import DisplayUserNameWithOption from "../Utils/DisplayName";
import { ReactComponent as FullSignalIcon } from "../assets/signal full.svg";
import { ReactComponent as StrongSignalIcon } from "../assets/signal strong.svg";
import { ReactComponent as MediumSignalIcon } from "../assets/signal medium.svg";
import { ReactComponent as LowSignalIcon } from "../assets/signal low.svg";
import { ReactComponent as VeryLowSignalIcon } from "../assets/signal very low.svg";
import { videoSettings } from "../../../Utils/Constants";
import ZoomInIcon from "../../../icons/zoom-in.png";
import ZoomOutIcon from "../../../icons/zoom-out.png";
import { ReactComponent as SignalLoading } from "../assets/signalLoading.svg";

let networkColor = (connectionQuality) =>
  connectionQuality ? (
    connectionQuality < 20 ? (
      <VeryLowSignalIcon style={{ height: 24, width: 24 }} />
    ) : connectionQuality < 40 ? (
      <LowSignalIcon style={{ height: 24, width: 24 }} />
    ) : connectionQuality < 60 ? (
      <MediumSignalIcon style={{ height: 24, width: 24 }} />
    ) : connectionQuality < 80 ? (
      <StrongSignalIcon style={{ height: 24, width: 24 }} />
    ) : (
      <FullSignalIcon style={{ height: 24, width: 24 }} />
    )
  ) : (
    <SignalLoading style={{ height: 24, width: 24 }} />
  );
const AbsoluteView = (props) => {
  const nameLabelProps = {
    // connectionQuality:
    //   props.connectionQuality && props.connectionQuality[props.user.id],
    noisyMics: props.noisyMics,
    muteUser: props.muteUser,
    user: props.user,
    pinnedUserId: props.pinnedUserId,
    pinUser: props.pinUser,
    localizedObject: props.localizedObject,
    unPinUser: props.unPinUser,
    backgroundColor: props.backgroundColor,
  };

  const isScreenShare = props.user.name.includes("(Screen)");

  if (props.mainVideo === false) {
    return (
      <>
        <div className='absolute-top-right'>
          {props.pinnedUserId == props.user.id && (
            <div
              data-testid='cc-unPinButton'
              onClick={() => {
                props.unPinUser();
              }}
              style={{ cursor: "pointer" }}
            >
              <PinIcon className={"tile-pinned-ui-un-pin-icon"} alt='Pin' />
            </div>
          )}
        </div>
        <div className='absolute-bottom-left'>
          <DisplayUserNameWithOption {...nameLabelProps} />
        </div>
        <div className='absolute-bottom-right'>{networkColor(props.connectionQuality)}</div>
      </>
    );
  }

  return (
    <>
      <div className='absolute-top-left'>
        {videoSettings.nameLabel.visible && videoSettings.nameLabel.position === "top-left" && (
          <DisplayUserNameWithOption {...nameLabelProps} />
        )}
        {videoSettings.network.visible &&
          videoSettings.network.position === "top-left" &&
          networkColor(props.connectionQuality)}
        {videoSettings.fitButton.visible &&
          !isScreenShare &&
          videoSettings.fitButton.position === "top-left" &&
          props.user.isVideoMuted === false &&
          (props.fitVideo ? (
            <img
              src={ZoomOutIcon}
              style={{ height: 24 }}
              onClick={props.toggleFitVideo}
              alt='FullscreenExit'
              className='pin-image'
            />
          ) : (
            <img
              src={ZoomInIcon}
              style={{ height: 24 }}
              onClick={props.toggleFitVideo}
              alt='Fullscreen'
              className='pin-image'
            />
          ))}
      </div>
      <div className='absolute-top-right'>
        {videoSettings.nameLabel.visible && videoSettings.nameLabel.position === "top-right" && (
          <DisplayUserNameWithOption {...nameLabelProps} />
        )}
        {videoSettings.network.visible &&
          videoSettings.network.position === "top-right" &&
          networkColor(props.connectionQuality)}
        {videoSettings.fitButton.visible &&
          !isScreenShare &&
          videoSettings.fitButton.position === "top-right" &&
          props.user.isVideoMuted === false &&
          (props.fitVideo ? (
            <img
              src={ZoomOutIcon}
              style={{ height: 24 }}
              onClick={props.toggleFitVideo}
              alt='FullscreenExit'
              className='pin-image'
            />
          ) : (
            <img
              src={ZoomInIcon}
              style={{ height: 24 }}
              onClick={props.toggleFitVideo}
              alt='Fullscreen'
              className='pin-image'
            />
          ))}
        {props.pinnedUserId == props.user.id && (
          <div
            data-testid='cc-unPinButton'
            onClick={() => {
              props.unPinUser();
            }}
            style={{ cursor: "pointer" }}
          >
            <PinIcon className={"tile-pinned-ui-un-pin-icon"} alt='Pin' />
          </div>
        )}
      </div>
      <div className='absolute-bottom-left'>
        {videoSettings.nameLabel.visible && videoSettings.nameLabel.position === "bottom-left" && (
          <DisplayUserNameWithOption {...nameLabelProps} />
        )}
        {videoSettings.fitButton.visible &&
          !isScreenShare &&
          videoSettings.fitButton.position === "bottom-left" &&
          props.user.isVideoMuted === false &&
          (props.fitVideo ? (
            <img
              src={ZoomOutIcon}
              style={{ height: 24 }}
              onClick={props.toggleFitVideo}
              alt='FullscreenExit'
              className='pin-image'
            />
          ) : (
            <img
              src={ZoomInIcon}
              style={{ height: 24 }}
              onClick={props.toggleFitVideo}
              alt='Fullscreen'
              className='pin-image'
            />
          ))}
        {videoSettings.network.visible &&
          videoSettings.network.position === "bottom-left" &&
          networkColor(props.connectionQuality)}
      </div>
      <div className='absolute-bottom-right'>
        {videoSettings.nameLabel.visible && videoSettings.nameLabel.position === "bottom-right" && (
          <DisplayUserNameWithOption {...nameLabelProps} />
        )}
        {videoSettings.network.visible &&
          videoSettings.network.position === "bottom-right" &&
          networkColor(props.connectionQuality)}
        {videoSettings.fitButton.visible &&
          !isScreenShare &&
          videoSettings.fitButton.position === "bottom-right" &&
          props.user.isVideoMuted === false &&
          (props.fitVideo ? (
            <img
              src={ZoomOutIcon}
              style={{ height: 24 }}
              onClick={props.toggleFitVideo}
              alt='FullscreenExit'
              className='pin-image'
            />
          ) : (
            <img
              src={ZoomInIcon}
              style={{ height: 24 }}
              onClick={props.toggleFitVideo}
              alt='Fullscreen'
              className='pin-image'
            />
          ))}
      </div>
      {/* <DisplayUserNameWithOption {...nameLabelProps} />
      <div
        style={{
          // margin: 8,
          position: "absolute",
          bottom: 8,
          right: 8,
        }}
      >
        {networkColor(props.connectionQuality)}
      </div> */}
    </>
  );
};

AbsoluteView.propTypes = {
  connectionQuality: PropTypes.number.isRequired,
  noisyMics: PropTypes.bool.isRequired,
  muteUser: PropTypes.func.isRequired,
  pinnedUserId: PropTypes.string.isRequired,
  pinUser: PropTypes.func.isRequired,
  unPinUser: PropTypes.func.isRequired,
  localizedObject: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  toggleFitVideo: PropTypes.func.isRequired,
  fitVideo: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string,
  mainVideo: PropTypes.bool,
};

export default AbsoluteView;
