const ADD_LOCAL_URL = "ADD_LOCAL_URL";
const ADD_REMOTE_URL = "ADD_REMOTE_URL";
const ADD_LOCAL_TRACK_ID = "ADD_LOCAL_TRACK_ID";

const initialState = {
  localUrl: "",
  localTrackId: "",
  remoteUrl: "",
};

const urlReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case ADD_LOCAL_URL:
      //console.log("addLocalUrl data ", action.payload);
      newState.localUrl = action.payload.localUrl;
      break;

    case ADD_LOCAL_TRACK_ID:
      //console.log("addLocalTrackId: ", action.payload);
      newState.localTrackId = action.payload;
      break;

    case ADD_REMOTE_URL:
      //console.log("addRemoteUrl data ", action.payload);
      newState.remoteUrl = action.payload.remoteUrl;
      break;

    default:
      break;
  }
  return newState;
};

export default urlReducer;
