import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import reducers from "./reducers/reducer";

const rootReducer = combineReducers(reducers);
var storeObj = createStore(rootReducer);

const htmlElement = document.getElementById("root");

function startApp() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={storeObj}>
        <App htmlElement={htmlElement} />
      </Provider>
    </React.StrictMode>,
    htmlElement
  );
}

// function inIframe() {
//   try {
//     return window.self !== window.top;
//   } catch (e) {
//     return true;
//   }
// }
// if (false) {
//   if (inIframe()) {
//     if (
//       (document.referrer &&
//         (document.referrer === "https://vuejs.cometchat-dev.com/meeting" ||
//           document.referrer === "https://vuejs.cometchat-dev.com" ||
//           document.referrer === "https://vuejs.cometchat-dev.com/meeting/")) ||
//       document.referrer === "https://www.saynamaste.in/meeting" ||
//       document.referrer === "https://www.saynamaste.in" ||
//       document.referrer === "https://www.saynamaste.in/meeting/" ||
//       document.referrer === "https://saynamaste.in/meeting" ||
//       document.referrer === "https://saynamaste.in" ||
//       document.referrer === "https://saynamaste.in/meeting/"
//     ) {
//       startApp();
//     }
//   }
// } else {
//   startApp();
// }
// startApp();
document.getElementById("start-calling").addEventListener("click", () => {
  startApp();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
