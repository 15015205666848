import { CALLING_MODES } from "./Constants";

const sharedObjectOriginal = {
  mode: "SIDEBAR",
  isPresenter: false,
};

const handler = {
  get(target, prop) {
    if (prop === "isPresenter") {
      if (target.mode === CALLING_MODES.PRESENTER) {
        return target.isPresenter ? true : false;
      }
      return undefined;
    }
    return Reflect.get(...arguments);
  },
};

export const sharedObject = new Proxy(sharedObjectOriginal, handler);
