import React from "react";

// import VideoCamOnIcon from "@material-ui/icons/Videocam";
// import VideoCamOffIcon from "@material-ui/icons/VideocamOff";
import { ReactComponent as VideoCamOffIcon } from "../assets/VideoCamOff-bottom.svg";
import { ReactComponent as VideoCamOnIcon } from "../assets/VideoCamOn-bottom.svg";
import SwitchVideoIcon from "@material-ui/icons/SwitchVideo";
import { IconButton, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import WarningIcon from "@material-ui/icons/Warning";
import debounce from "lodash.debounce";

const VideoButton = (props) => {
  if (props.isAudioOnly && props.usersLength != 2) {
    return null;
  }
  /**
   * getIcon function simple returns the icon based on current vidoe state
   *@returns {Icon}
   */
  const getIcon = () => {
    if (props.isAudioOnly) {
      return (
        <SwitchVideoIcon
          fontSize='medium'
          className={"bottom-buttons-icon-color"}
          data-testid='cc-video-switch'
        />
      );
    } else if (props.isMuted) {
      return (
        <VideoCamOffIcon
          fontSize='medium'
          className={"bottom-buttons-icon-color"}
          data-testid='cc-video-off'
        />
      );
    } else {
      return (
        <VideoCamOnIcon
          fontSize='medium'
          className={"bottom-buttons-icon-color"}
          data-testid='cc-video-on'
        />
      );
    }
  };

  //this is a check to make sure that do we need to render the videoButton
  //it can be hidden when we cannot create a video track or when developer whats to hide it
  if (props.isVisible) {
    return (
      <Tooltip
        disableHoverListener={props.noTrack}
        title={
          props.isMuted
            ? props.localizedObject["UN_MUTE_VIDEO_TEXT"]
            : props.isAudioOnly
            ? props.localizedObject["SWITCH_TO_VIDEO_CALL"]
            : props.localizedObject["MUTE_VIDEO_TEXT"]
        }
        arrow
      >
        <IconButton
          id={"videoButton"}
          data-testid='cc-video'
          size={"small"}
          component='span'
          className={"bottom-buttons-icon-button"}
          onClick={debounce((e) => {
            e.stopPropagation();
            props.onPress();
          }, 500)}
        >
          <div
            data-testid={"videoButtonIconContainer"}
            className={`cc-bottom-buttons-icon-container ${
              props.isAudioOnly
                ? "cc-switch-video-icon-container"
                : props.isMuted
                ? "cc-video-icon-container-muted"
                : "cc-video-icon-container"
            }`}
          >
            {getIcon()}
          </div>
          {props.noTrack ? (
            <Tooltip title={props.localizedObject["CAMERA_ERROR_MSG"]} arrow>
              <div className='track-error-container'>
                <WarningIcon
                  style={{
                    aspectRatio: 1,
                    height: 16,
                    width: 16,
                    color: "#fff",
                  }}
                />
              </div>
            </Tooltip>
          ) : null}
        </IconButton>
      </Tooltip>
    );
  } else {
    return null;
  }
};

VideoButton.defaultProps = {
  isMuted: true,
  onPress: () => {},
  isVisible: false,
  localizedObject: {},
  noTrack: false,
};

VideoButton.propTypes = {
  isMuted: PropTypes.bool,
  isVisible: PropTypes.bool,
  localizedObject: PropTypes.object,
  noTrack: PropTypes.bool,
  onPress: PropTypes.func,
};

export default VideoButton;
