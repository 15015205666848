import React, { Component } from "react";
import "./UserVideo.css";

export default class AudioViewComponent extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
  }

  componentDidUpdate(props) {
    if (this.props.audioTrack && !!this.audioRef && this.props.audioTrack !== props.audioTrack) {
      this.props.audioTrack.attach(this.audioRef.current);
    }
  }

  componentDidMount() {
    if (this.props.audioTrack && !!this.audioRef) {
      if (
        window.room &&
        window.room.getLocalAudioTrack &&
        window.room.getLocalAudioTrack()?.getId
      ) {
        if (window.room.getLocalAudioTrack().getId() != this.props.audioTrack.getId()) {
          this.props.audioTrack.attach(this.audioRef.current);
        }
      } else {
        this.props.audioTrack.attach(this.audioRef.current);
      }
    }
  }

  componentWillUnmount() {
    if (this.audioRef && this.audioRef.current && this.props.audioTrack) {
      this.props.audioTrack.detach(this.audioRef.current);
    }
  }

  render() {
    return <audio style={{ display: "none" }} autoPlay={true} ref={this.audioRef} />;
  }
}
